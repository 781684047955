.item-container{
    padding: 10px;
    border-bottom: solid 1px #eee;
}

p{
    margin:0
}

.photo{
    box-shadow: 0 1px 8px 1px #aaa;
    border: solid 2px white;
}